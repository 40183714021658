<template>
  <div class="wrapper" @click="toContact">
    <el-carousel height="3.4rem" :interval="5000">
      <el-carousel-item v-for="item in carouseList" :key="item.id">
        <img :src="item.path" class="carouselImg" alt="西瓜悦动,西瓜互动,banner" />
        <div class="sloganWrap" v-if="item.id === '4' || item.id === '5'">
          <div class="para1">{{ item.para1 }}</div>
          <div class="para2">{{ item.para2 }}</div>
          <div class="contactBtn" :style="{ color: item.color }">立即咨询</div>
        </div>
        <div class="dragonContactBtn" v-if="item.id === '2'">立即咨询</div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { getCarouselList } from '@/api/index';
import store from '@/store/index';
// import { timeoutTask } from '@/utils/index';

export default {
  components: {},
  data() {
    return {
      carouseList: [
        {
          id: '1',
          path: 'https://ustatic.hudongmiao.com/watermalen/poster111.png',
        },
        {
          id: '3',
          path: 'https://ustatic.hudongmiao.com/watermalen/dragonInSkyPoster.png',
        },
        {
          id: '6',
          path: 'https://ustatic.hudongmiao.com/watermalen/dragonPlayBallPoster.png',
        },
        {
          id: '2',
          path: 'https://static.livemew.com/livemew/image/bannerDragon.png',
        },
        {
          id: '4',
          path: 'https://ustatic.hudongmiao.com/%20miao/watermalen/banner1.png',
          para1: '互动科技专家',
          para2: '多场景大屏互动领导品牌',
          color: 'rgba(180,154,241,1)',
        },
        {
          id: '5',
          path: 'https://ustatic.hudongmiao.com/%20miao/watermalen/banner2.png',
          para1: '趣味大屏互动',
          para2: '一亿用户体验过的精彩互动',
          color: 'rgba(38,37,206,1)',
        },
      ],
      lrBoxVisible: false,
    };
  },
  created() {
    getCarouselList().then((res) => {
      console.log('轮播图:', res);
      (function () {
        const _53code = document.createElement('script');
        _53code.src = 'https://tb.53kf.com/code/code/2c891055109c51301f8b2e9df6910cdf1/2';
        const s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(_53code, s);
      }());
      (function () {
        const hm = document.createElement('script');
        hm.src = 'https://hm.baidu.com/hm.js?e7308b7aad997a9322a93620c415c713';
        const s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(hm, s);
      }());
    });
  },
  methods: {
    toLink(link) {
      if (link === 'yx') {
        if (store.state.token) {
          window.location.href = `${window.location.origin}/hm-customer/index.html#/app/yx`;
        } else {
          this.openLrBox(0);
        }
      } else if (link) {
        window.open(link, '_blank');
      }
    },
    openLrBox(type) {
      this.lrBoxVisible = true;
      this.$nextTick(() => {
        this.$refs.lrBoxDom.openBoxByType(type);
      });
    },
    handleCloseLrBox() {
      this.lrBoxVisible = false;
    },
    toContact() {
      // eslint-disable-next-line no-undef
      hz6d_is_exist(
        'setIsinvited()%3Bwindow.open(#liyc#https%3A%2F%2Fwww2.53kf.com%2FwebCompany.php%3Fkf_sign%3Djk2MTMTY5MA5NjEyMzM1NDc4NTc0MDA4NzI4NTA1MDE%3D%26arg%3D10850501%26style%3D2%26language%3Dcn%26charset%3DGBK%26kflist%3Doff%26kf%3D%26zdkf_type%3D1%26lnk_overflow%3D0%26callback_id6ds%3D%26referer%3Dhttp%253A%252F%252Fdev.joymew.com%252F%2523%252F%26keyword%3Dhttp%253A%252F%252Fdev.joymew.com%252F%26tfrom%3D1%26tpl%3Dcrystal_blue%26uid%3D9641cd286d3bab2398c7d1731200a589%26is_group%3D%26is_group%3D#liyc#%2C%20#liyc#_blank#liyc#%2C%20#liyc#height%3D600%2Cwidth%3D800%2Ctop%3D132%2Cleft%3D368%2Cstatus%3Dyes%2Ctoolbar%3Dno%2Cmenubar%3Dno%2Cresizable%3Dyes%2Cscrollbars%3Dno%2Clocation%3Dno%2Ctitlebar%3Dno#liyc#)',
      );
    },
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  .carouselImg {
    cursor: pointer;
    width: 100%;
    height: 100%;
  }
  .sloganWrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #ffffff;
    position: absolute;
    top: 150px;
    left: 200px;
    cursor: pointer;
    .para1 {
      font-size: 80px;
      letter-spacing: 4px;
    }
    .para2 {
      font-size: 40px;
      letter-spacing: 4px;
      margin-top: 40px;
    }
    .contactBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 60px;
      color: rgba(180, 154, 241, 1);
      border-radius: 5px;
      background-color: rgba(255, 255, 255, 0.8);
      font-size: 24px;
      font-weight: 500;
      margin-top: 120px;
      &:hover {
        background-color: rgba(255, 255, 255, 1);
      }
    }
  }
  .dragonContactBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 60px;
    color: rgba(205, 6, 13, 1);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.8);
    font-size: 24px;
    font-weight: 500;
    position: absolute;
    bottom: 120px;
    left: 200px;
    cursor: pointer;
    &:hover {
      background-color: rgba(255, 255, 255, 1);
    }
  }
}
</style>

<template>
  <div class="scene-schema">
    <div class="title">多场景互动方案</div>
    <div class="sub-title">满足各种活动场景的互动需求</div>
    <div class="card-wrap">
      <div class="card" v-for="(item, index) in sceneList" :key="index">
        <img :src="item.url" :alt="item.name" class="func-img" />
        <div class="bottom-nav">
          <div class="go-test">
            <div class="play"></div>
            立即体验
          </div>
          <div class="bottom-nav-title">{{ item.name }}</div>
          <div class="bottom-nav-sub-title">{{ item.desc }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sceneList: [
        {
          name: '企业年会',
          desc: '红包抽奖，引爆年会现场',
          url: 'https://static.livemew.com/livemew/home/S1.jpg',
        },
        {
          name: '培训团建',
          desc: '答题培训功能，满足培训需要',
          url: 'https://static.livemew.com/livemew/home/S2.jpg',
        },
        {
          name: '商超促销',
          desc: '红包抽奖，引爆年会现场',
          url: 'https://static.livemew.com/livemew/home/S3.jpg',
        },
        {
          name: '校园活动',
          desc: '创意互动玩法，增添活动乐趣',
          url: 'https://static.livemew.com/livemew/home/S4.jpg',
        },
        {
          name: '展览展会',
          desc: '活动聚集人气，提升吸引力',
          url: 'https://static.livemew.com/livemew/home/S5.jpg',
        },
        {
          name: '峰会论坛',
          desc: '3D签到抽奖，彰显科技实力',
          url: 'https://static.livemew.com/livemew/home/S6.jpg',
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.scene-schema {
  height: 1095px;
  background: #fff;
  padding-top: 100px;
  box-sizing: border-box;
  div {
    box-sizing: border-box;
  }
  .title {
    margin: 0 auto;
    text-align: center;
    font-size: 40px;
  }
  .sub-title {
    margin: 0 auto;
    margin-top: 24px;
    text-align: center;
    color: #889099;
    font-size: 20px;
  }

  .card-wrap {
    height: 560px;
    // background: gray;
    margin: 34px 0;
    padding: 0 300px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .card {
      width: 424px;
      height: 355px;
      background: #ffffff;
      border-radius: 12px;
      box-shadow: 0px 25.7px 72.81px 0px rgba(86, 164, 255, 0.08);
      &:hover {
        transform: scale(1.03);
      }
      .func-img {
        width: 424px;
        height: 241px;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        background: #167bed;
      }
      .bottom-nav {
        padding: 14px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        position: relative;

        .bottom-nav-title {
          font-size: 24px;
          color: #333333;
          font-weight: 500;
        }
        .bottom-nav-sub-title {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.4);
        }
        .go-test {
          width: 108px;
          height: 48px;
          background: #167bed;
          border-radius: 4px;
          position: absolute;
          cursor: pointer;
          top: 14px;
          right: 16px;
          color: #fff;
          font-size: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
</style>

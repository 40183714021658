<template>
  <div class="recommend-game">
    <div class="title">热门玩法推荐</div>
    <div class="sub-title">60+玩法任选 活动氛围高涨</div>
    <div class="tab-wrap">
      <div
        class="tab-item"
        :class="{ 'tab-active': currentIndex == 0 }"
        @click="changeIndex(0)"
        data-index="0"
        @mouseenter="moveTabIn"
      >
        热门推荐
      </div>
      <div
        class="tab-item"
        :class="{ 'tab-active': currentIndex == 1 }"
        @click="changeIndex(1)"
        data-index="1"
        @mouseenter="moveTabIn"
      >
        互动游戏
      </div>
      <div
        class="tab-item"
        :class="{ 'tab-active': currentIndex == 2 }"
        @click="changeIndex(2)"
        data-index="2"
        @mouseenter="moveTabIn"
      >
        红包抽奖
      </div>
    </div>
    <div class="card-wrap">
      <div class="card" v-for="(item, index) in topList" :key="index">
        <!-- <div class="func-img"></div> -->
        <img
          v-lazy="item.webp"
          :alt="item.name"
          srcset=""
          class="func-img"
        />
        <!-- <video
          :src="item.src"
          muted
          autoplay
          loop
          class="func-img"
          style="object-fit: fill"
        ></video> -->
        <div class="bottom-nav">
          <div class="go-test" @click="goTry" :data-path="item.path">
            <div class="play"></div>
            进入体验
          </div>
          <div class="bottom-nav-title">{{ item.name }}</div>
          <div class="bottom-nav-sub-title">{{ item.desc }}</div>
        </div>
      </div>
    </div>
    <div class="more-btn" @click="findMore">发现更多功能</div>
  </div>
</template>

<script>
import { ENTERPRISE } from '@/assets/constant/default';

// 热门推荐
const topAList = [
  {
    name: '品牌签到',
    desc: '自定义背景，满足多场景需求',
    path: '',
    webp: 'https://static.livemew.com/livemew/image/case/hot/品牌签到.webp',
  },
  {
    name: '品牌抽奖',
    desc: '最新一代抽奖系统，经历万场迭代优化',
    path: 'bigScreenLottery',
    webp: 'https://static.livemew.com/livemew/image/case/hot/巨幕抽奖.webp',

  },
  {
    name: '数字抽奖',
    desc: '可支持数字+名字组合，可灵活操作',
    path: 'listLottery',
    webp: 'https://static.livemew.com/livemew/image/case/hot/名单抽奖.webp',

  },
  {
    name: '红包雨',
    desc: '适用于将会场的气氛推向一个顶点',
    path: 'hby',
    webp: 'https://static.livemew.com/livemew/image/case/hot/摇红包.webp',

  },
  {
    name: '飞龙在天',
    desc: '龙年摇一摇',
    path: 'dragonInTheSky',
    webp: 'https://static.livemew.com/livemew/image/case/hot/flzt.gif',

  },
  {
    name: '谁最牛',
    desc: '摇一摇竖版游戏，根据手机摇晃速度排名',
    path: 'cattleShake',
    webp: 'https://static.livemew.com/livemew/image/case/hot/szn.gif',

  },
  {
    name: '数钞票',
    desc: '眼疾还需手快，根据正确敲击排名',
    path: 'countMoney',
    webp: 'https://static.livemew.com/livemew/image/case/hot/scp.gif',

  },
  {
    name: '游龙戏珠',
    desc: '龙年划一划',
    path: 'dragonPlayBead',
    webp: 'https://static.livemew.com/livemew/image/case/hot/ylxz.gif',
  },
];
// 互动游戏
const topBList = [
  {
    name: '礼物包场',
    desc: '最新一代抽奖系统，经历万场迭代优化',
    path: '',
    webp: 'https://static.livemew.com/livemew/image/case/hd/包场包场.webp',

  },
  {
    name: '疯狂游泳',
    desc: '适用于将会场的气氛推向一个顶点',

    path: 'swimShake',
    webp: 'https://static.livemew.com/livemew/image/case/hd/疯狂游泳.webp',

  },
  {
    name: '兔飞猛进',
    desc: '适用于将会场的气氛推向一个顶点',
    path: 'rubbitShake',
    webp: 'https://static.livemew.com/livemew/image/case/hd/tfmj.gif',

  },
  {
    name: '切水果',
    desc: '适用于将会场的气氛推向一个顶点',

    path: 'cutFruit',
    webp: 'https://static.livemew.com/livemew/image/case/hd/切西瓜.webp',

  },
  {
    name: '兔子投篮',
    desc: '适用于将会场的气氛推向一个顶点',

    path: 'basketballShoot',
    webp: 'https://static.livemew.com/livemew/image/case/hot/tztl.gif',

  },
  {
    name: '手写签到',
    desc: '适用于将会场的气氛推向一个顶点',

    path: '',
    webp: 'https://static.livemew.com/livemew/image/case/hd/手写签到.webp',

  },
  {
    name: '麻将',
    desc: '适用于将会场的气氛推向一个顶点',

    path: 'mahjongV1',
    webp: 'https://static.livemew.com/livemew/image/case/hot/qs.gif',

  },
  {
    name: '照片霸屏',
    desc: '适用于将会场的气氛推向一个顶点',

    path: '',
    webp: 'https://static.livemew.com/livemew/image/case/hd/照片霸屏.webp',

  },
];
// 红包抽奖
const topCList = [
  {
    name: '3D抽奖',
    desc: '适用于将会场的气氛推向一个顶点',
    webp: 'https://static.livemew.com/livemew/image/case/draw/3D抽奖.webp',
    path: 'threeDLotteryEnterprise',
  },
  {
    name: '猜红包',
    desc: '适用于将会场的气氛推向一个顶点',
    webp: 'https://static.livemew.com/livemew/image/case/draw/猜红包.webp',
    path: 'hbWall',

  },
  {
    name: '地球抽奖',
    desc: '适用于将会场的气氛推向一个顶点',
    webp: 'https://static.livemew.com/livemew/image/case/draw/地球抽奖.webp',
    path: 'earthLottery',
  },
  {
    name: '点红包',
    desc: '适用于将会场的气氛推向一个顶点',
    webp: 'https://static.livemew.com/livemew/image/case/draw/点红包.webp',
    path: 'clickHb',

  },
  {
    name: '红包游戏',
    desc: '适用于将会场的气氛推向一个顶点',
    webp: 'https://static.livemew.com/livemew/image/case/draw/红包游戏.webp',
    path: 'hby',

  },
  {
    name: '开宝箱',
    desc: '适用于将会场的气氛推向一个顶点',
    webp: 'https://static.livemew.com/livemew/image/case/draw/开宝箱.webp',
    path: 'kbx',

  },
  {
    name: '照片抽奖',
    desc: '适用于将会场的气氛推向一个顶点',
    webp: 'https://static.livemew.com/livemew/image/case/draw/照片抽奖.webp',
    path: '',

  },
  {
    name: '争分夺秒',
    desc: '适用于将会场的气氛推向一个顶点',
    webp: 'https://static.livemew.com/livemew/image/case/draw/争分夺秒.webp',
    path: '',

  },
];

export default {
  name: 'RecommendGame',
  data() {
    return {
      currentIndex: 0,
      topAList,
      topBList,
      topCList,
    };
  },
  computed: {
    topList() {
      const { currentIndex } = this;
      if (currentIndex === 0) {
        return topAList;
      }
      if (currentIndex === 1) {
        return topBList;
      }
      if (currentIndex === 2) {
        return topCList;
      }
      return [];
    },
  },
  methods: {
    changeIndex(index) {
      this.currentIndex = index;
      console.log(index);
    },
    findMore() {
      console.log('发现更多功能');
      window.open(ENTERPRISE, '_blank');
    },
    goTry(e) {
      console.log('尝试');
      const { path } = e.target.dataset;
      const target = ENTERPRISE.replace('/#/', `/#/${path}`);
      window.open(target, '_blank');
    },
    moveTabIn(e) {
      const { index } = e.target.dataset;
      this.changeIndex(Number(index));
    },
  },
};
</script>

<style lang="less" scoped>
.recommend-game {
  height: 1095px;
  background: #fff;
  padding-top: 100px;
  box-sizing: border-box;
  div {
    box-sizing: border-box;
  }
  .title {
    margin: 0 auto;
    text-align: center;
    font-size: 40px;
  }
  .sub-title {
    margin: 0 auto;
    margin-top: 24px;
    text-align: center;
    color: #889099;
    font-size: 20px;
  }
  .tab-wrap {
    width: 680px;
    margin: 0 auto;
    font-size: 32px;
    margin-top: 32px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .tab-item {
      // flex-grow: 1;
      cursor: pointer;
      position: relative;
    }
    .tab-active {
      color: #167bed;
      &::after {
        content: "";
        position: absolute;
        width: 47px;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        height: 6px;
        background: #167bed;
        border-radius: 15px;
      }
    }
  }
  .card-wrap {
    height: 560px;
    // background: gray;
    margin: 34px 0;
    padding: 0 300px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    .card {
      width: 312px;
      height: 268px;
      background: #ffffff;
      border-radius: 12px;
      margin-bottom: 24px;
      box-shadow: 0px 3px 12px 0px rgba(86, 164, 255, 0.08);
      &:hover {
        transform: scale(1.03);
      }
      .func-img {
        width: 312px;
        height: 176px;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        background: #167bed;
      }
      .bottom-nav {
        padding: 14px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        position: relative;
        .bottom-nav-title {
          font-size: 24px;
          color: #333333;
          font-weight: 500;
        }
        .bottom-nav-sub-title {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.4);
        }
        .go-test {
          position: absolute;
          cursor: pointer;
          top: 14px;
          right: 16px;
          color: #167bed;
          font-size: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          .play {
            width: 16px;
            height: 16px;
            margin-right: 4px;
            border-radius: 50%;
            // background: #167bed;
            background-size: 100% 100%;
            background-image: url('https://static.livemew.com/livemew/image/playicon.jpg');
          }
        }
      }
    }
  }
  .more-btn {
    margin: 0 auto;
    margin-top: 50px;
    width: 300px;
    height: 71px;
    background: #167bed;
    border-radius: 4px;
    color: #ffffff;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
</style>

<template>
  <div class="enterprise-used">
    <div class="title">500强企业都在用</div>
    <div class="sub-title">客户的满意，是我们的追求。</div>
    <div class="card-wrap">
      <div class="card"></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.enterprise-used {
  height: 767px;
  background: #fff;
  padding-top: 100px;
  box-sizing: border-box;
  div {
    box-sizing: border-box;
  }
  .title {
    margin: 0 auto;
    text-align: center;
    font-size: 40px;
  }
  .sub-title {
    margin: 0 auto;
    margin-top: 24px;
    text-align: center;
    color: #889099;
    font-size: 20px;
  }

  .card-wrap {
    margin: 34px 0;
    margin-top: 50px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .card {
      width: 1322px;
      height: 417px;
      background-size: 100% 100%;
      background-image: url('https://ustatic.hudongmiao.com/%20miao/watermalen/logoWall2.png');
    }
  }
}
</style>

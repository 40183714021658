<template>
  <div class="our-advantage">
    <div class="title">深耕互动领域，为您提供持续的技术支持</div>
    <div class="sub-title">秉着极致的服务理念，为您提供最优质的的产品体验</div>
    <div class="card-wrap">
      <div class="card">
        <div class="tab-wrap">
          <div
            class="tab"
            :class="{ active: currentIndex == 0 }"
            style="margin-top: 0"
            @click="changeIndex(0)"
            data-index="0"
            @mouseenter="moveOnIt"
          >
            产品丰富
          </div>
          <div
            class="tab"
            :class="{ active: currentIndex == 1 }"
            @click="changeIndex(1)"
            data-index="1"
            @mouseenter="moveOnIt"
          >
            系统稳定
          </div>
          <div
            class="tab"
            :class="{ active: currentIndex == 2 }"
            @click="changeIndex(2)"
            data-index="2"
            @mouseenter="moveOnIt"
          >
            服务全面
          </div>
        </div>
        <!-- <transition name="slide-fade"> -->
        <div class="intro-wrap" v-if="currentIndex == 0">
          <div class="intro-title">产品丰富</div>
          <div class="intro-info-wrap">
            <div class="intro-item">·20+大屏互动玩法，百项功能</div>
            <div class="intro-item">·活动全流程闭环</div>
            <div class="intro-item">·专业级功能玩法</div>
            <div class="intro-item">·热门功能不断上新</div>
          </div>
          <div class="line"></div>
          <div class="quickly-test"  @click="trial">立即体验</div>
        </div>
        <!-- </transition> -->
        <!-- <transition name="slide-fade"> -->
        <div class="intro-wrap" v-if="currentIndex == 1">
          <div class="intro-title">系统稳定</div>
          <div class="intro-info-wrap">
            <div class="intro-item">·高性能服务器，超稳超安全</div>
            <div class="intro-item">·单日27万人实战验证</div>
            <div class="intro-item">·云原生架构支持</div>
            <div class="intro-item" style="visibility: hidden">
              ·云原生架构支持
            </div>
          </div>
          <div class="line"></div>
          <div class="quickly-test"  @click="trial">立即体验</div>
        </div>
        <!-- </transition> -->
        <!-- <transition name="fade-in"> -->
        <div class="intro-wrap" v-if="currentIndex == 2">
          <div class="intro-title">服务全面</div>
          <div class="intro-info-wrap">
            <div class="intro-item">·7*24小时在线服务</div>
            <div class="intro-item">·专业客户群及时响应</div>
            <div class="intro-item">·平台优秀讲师培训</div>
            <div class="intro-item">·1对1活动流程指导</div>
          </div>
          <div class="line"></div>
          <div class="quickly-test" @click="trial">立即体验</div>
        </div>
        <!-- </transition> -->
      </div>
    </div>
  </div>
</template>

<script>
// import { ENTERPRISE } from '@/assets/constant/default';
import { ENTERPRISE } from '@/assets/constant/default';

export default {
  data() {
    return {
      currentIndex: 0,
    };
  },
  methods: {
    changeIndex(i) {
      this.currentIndex = i;
    },
    moveOnIt(e) {
      const { index } = e.target.dataset;
      this.changeIndex(index);
    },
    trial() {
      window.open(ENTERPRISE, '_blank');
    },
  },
};
</script>
<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
<style lang="less" scoped>
.our-advantage {
  // height: 767px;
  background: #fff;
  padding-top: 100px;
  box-sizing: border-box;
  height: 899px;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(2px);
  background-size: 100% 100%;
  background-image: url("https://static.livemew.com/livemew/home/zzdd.jpg");
  color: #ffffff;
  div {
    box-sizing: border-box;
  }
  .title {
    margin: 0 auto;
    text-align: center;
    font-size: 40px;
  }
  .sub-title {
    margin: 0 auto;
    margin-top: 24px;
    text-align: center;
    font-size: 20px;
    // margin-bottom: 50px;
  }

  .card-wrap {
    margin: 34px 0;
    margin-top: 44px;

    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .card {
      width: 1320px;
      height: 649px;
      background: rgba(23, 24, 27, 0.4);
      backdrop-filter: blur(47px);
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      .tab-wrap {
        width: 424px;
        height: 649px;
        background: rgba(23, 24, 27, 0.6);
        padding: 60px 40px;
        .tab {
          width: 344px;
          height: 93px;
          &.active {
            background: #167bed;
          }
          cursor: pointer;
          border-radius: 4px;
          padding: 24px;
          margin-top: 105px;
          font-size: 32px;
        }
      }
      .intro-wrap {
        padding-top: 100px;
        .intro-title {
          margin-left: 132px;
          font-size: 36px;
        }
        .intro-info-wrap {
          margin-top: 50px;
          padding-left: 132px;

          .intro-item {
            margin-top: 20px;
            font-size: 28px;
          }
        }
        .line {
          width: 736px;
          height: 1px;
          opacity: 0.3;
          background: #d9d9d9;
          margin: 50px 0;
          margin-left: 80px;
        }
        .quickly-test {
          width: 192px;
          height: 62px;
          margin-left: 132px;
          cursor: pointer;
          background: #167bed;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
</style>

<template>
  <div class="maincontainer">
    <melon-header></melon-header>
    <banner></banner>
    <recommend-game></recommend-game>
    <scene-schema></scene-schema>
    <our-advantage></our-advantage>
    <class-case></class-case>
    <enterprise-used></enterprise-used>
    <about-us></about-us>
    <melon-footer></melon-footer>
  </div>
</template>

<script>
import banner from '@/components/layout/banner.vue';
import MelonHeader from '@/components/layout/MelonHeader.vue';
// import myFooter from '@/components/myFooter.vue';
import MelonFooter from '@/components/layout/MelonFooter.vue';

import RecommendGame from './components/RecommendGame.vue';
import SceneSchema from './components/SceneSchema.vue';
import ClassCase from './components/ClassCase.vue';
import EnterpriseUsed from './components/EnterpriseUsed.vue';
import OurAdvantage from './components/OurAdvantage.vue';
import AboutUs from './components/AboutUs.vue';

export default {
  components: {
    banner,
    MelonHeader,
    RecommendGame,
    SceneSchema,
    ClassCase,
    EnterpriseUsed,
    OurAdvantage,
    AboutUs,
    MelonFooter,
  },
  methods: {
    openQrcodeBox() {
      this.qrcodeBoxVisible = true;
    },
  },
};
</script>

<template>
  <div class="class-case">
    <div class="title">经典使用案例</div>
    <div class="sub-title">不一样的场合，有同一样的精彩</div>
    <div class="card-wrap">
      <div class="card-content">
        <div class="card" v-for="(item, index) in caseList" :key="index">
          <img :src="item.url" :alt="item.name" class="func-img" />
          <div class="bottom-nav">{{ item.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClassCase',
  data() {
    return {
      caseList: [
        {
          name: '2023喜创优品年度培训会',
          url: 'https://ustatic.hudongmiao.com/%20miao/watermalenCase/xcypndpxh.png',
        },
        {
          name: '2023南京婚庆行业交流峰会',
          url: 'https://ustatic.hudongmiao.com/%20miao/watermalenCase/hqhyjlfh.png',
        },
        {
          name: '2023友谊商贸交流论坛',
          url: 'https://ustatic.hudongmiao.com/%20miao/watermalenCase/yysmjllt.png',
        },
        {
          name: '淄博海月龙宫万人烤场',
          url: 'https://ustatic.hudongmiao.com/%20miao/watermalenCase/zbhy.jpg',
        },
        {
          name: '中建钢构培训会',
          url: 'https://ustatic.hudongmiao.com/%20miao/watermalenCase/zjgg.jpg',
        },
        {
          name: '秀起模特八周年庆典',
          url: 'https://ustatic.hudongmiao.com/%20miao/watermalenCase/xqmt.jpg',
        },
        {
          name: '十周岁生日宴',
          url: 'https://ustatic.hudongmiao.com/%20miao/watermalenCase/szss.jpg',
        },
        {
          name: '江淮企业年会',
          url: 'https://ustatic.hudongmiao.com/%20miao/watermalenCase/jhqy.jpg',
        },
        {
          name: '华为线下新品培训',
          url: 'https://ustatic.hudongmiao.com/%20miao/watermalenCase/hwxx.jpg',
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.class-case {
  height: 722px;
  background: #edeff6;
  padding-top: 100px;
  box-sizing: border-box;
  div {
    box-sizing: border-box;
  }
  .title {
    margin: 0 auto;
    text-align: center;
    font-size: 40px;
  }
  .sub-title {
    margin: 0 auto;
    margin-top: 24px;
    text-align: center;
    color: #889099;
    font-size: 20px;
  }

  .card-wrap {
    // height: 560px;
    // background: gray;
    margin: 34px 0;
    margin-top: 50px;
    padding: 0 178px;
    box-sizing: border-box;
    // display: flex;
    // justify-content: space-between;
    // align-items: flex-start;
    // flex-wrap: wrap;
    overflow-x: hidden;
    .card-content {
      display: flex;
      column-gap: 40px;
      animation: moveAni 20s linear infinite;
      .card {
        width: 490px;
        height: 372px;
        padding: 20px;
        background: #ffffff;
        border-radius: 12px;
        box-shadow: 0px 25.7px 72.81px 0px rgba(86, 164, 255, 0.08);
        flex-shrink: 0;
        &:hover {
          transform: scale(1.03);
        }
        .func-img {
          width: 458px;
          height: 258px;
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
          background: #167bed;
        }
        .bottom-nav {
          padding: 14px;
          font-size: 28px;
          display: flex;
          color: #333333;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }
      }
      &:hover {
        animation-play-state: paused;
      }
    }
  }
  .more-btn {
    margin: 0 auto;
    margin-top: 50px;
    width: 300px;
    height: 71px;
    background: #167bed;
    border-radius: 4px;
    color: #ffffff;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

@keyframes moveAni {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-1500px);
  }
}
</style>
